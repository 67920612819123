@use "sass:map";
@use "variables";

// Custom Mixins for Media Queries
// --------------------------------------------------------------------------------------------------------------------
//   @min-screen(width)                      // shortcut for @media screen and (min-width ...)
//   @max-screen(width)                      // shortcut for @media screen and (max-width ...)
//   @screen(min-width, max-width)           // shortcut for @media screen and (min-width ...) and (max-width ...)
// --------------------------------------------------------------------------------------------------------------------
@mixin screen($resMin: "0px", $resMax: "9999px") {
    @media screen and (min-width: $resMin) and (max-width: $resMax) {
        @content;
    }
}

@mixin max-screen($res) {
    @media screen and (max-width: $res) {
        @content;
    }
}

@mixin min-screen($res) {
    @media screen and (min-width: $res) {
        @content;
    }
}

// A mixin that will construct styles for a button based on the style type (eg. primary/secondary) and the values inside variables.scss
@mixin button-style($type) {
    background-color: get-color(button, $type, bg, default);
    color: get-color(button, $type, text, default);

    &:hover,
    &.is-hover,
    &.vm-button--hover {
        background-color: get-color(button, $type, bg, hover);
        color: get-color(button, $type, text, hover);
    }

    &:active,
    &.is-active,
    &.vm-button--active {
        background-color: get-color(button, $type, bg, pressed);
        color: get-color(button, $type, text, pressed);
    }

    &:disabled,
    &.is-disabled,
    &.vm-button--disabled {
        background-color: get-color(button, $type, bg, disabled) !important;
        color: get-color(button, $type, text, disabled) !important;
        cursor: default !important;
        pointer-events: none !important;
    }
}

// A function that returns a color from the $colors variable in variables.scss
@function get-color($keys...) {
    $result: variables.$colors;
    @each $key in $keys {
        $result: map.get($result, $key);
        // Handle error if a key does not exist in the map
        @if $result == null {
            @warn "Key `#{$key}` not found in `variables.$colors` map.";
            @return null;
        }
    }
    @return $result;
}

// Typography mixins based on the Figma design (Disease page) - figma.com/design/i1PEiNLhHwRNfALgk9Ieus/Vumedi-1.1-Website
// --------------------------------------------------------------------------------------------------------------------
//   .example-usage {
//       @include text-caption;
//   }
// --------------------------------------------------------------------------------------------------------------------
$disease-color-text-primary: #18191a;
$disease-color-text-secondary: #484f50;
$disease-color-text-tertiary: #666d6e;

@mixin text-caption {
    color: $disease-color-text-tertiary;

    font-family: variables.$fontQuaternary;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.05em;

    text-transform: uppercase;
}

@mixin text-medium {
    color: $disease-color-text-tertiary;

    font-family: variables.$fontQuaternary;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
}

@mixin text-medium-regular {
    color: get-color(text, tertiary);

    font-family: variables.$fontQuaternary;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

@mixin text-xs-regular {
    color: get-color(text, tertiary);

    font-family: variables.$fontQuaternary;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

@mixin text-xs-bold {
    color: get-color(text, tertiary);

    font-family: variables.$fontQuaternary;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.12px;
}

@mixin text-small-medium {
    color: get-color(text, tertiary);

    font-family: variables.$fontQuaternary;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;
}

@mixin text-large-medium {
    font-family: variables.$fontQuaternary;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@mixin text-title-small {
    color: $disease-color-text-primary;

    font-family: variables.$fontQuaternary;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
}

@mixin text-title-xsmall {
    color: $disease-color-text-primary;

    font-family: variables.$fontQuaternary;
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.02em;
}

@mixin text-title-xxsmall {
    font-family: variables.$fontQuaternary;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.02em;
}

@mixin text-bold-small {
    font-family: variables.$fontQuaternary;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.07px;
}

@mixin text-bold-medium {
    font-family: variables.$fontQuaternary;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.08px;
}

@mixin text-regular-small {
    font-family: variables.$fontQuaternary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.07px;
}

@mixin text-regular-xsmall {
    font-family: variables.$fontQuaternary;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
}

@mixin text-homefeed-title {
    color: get-color(text, primary);

    font-family: variables.$fontQuaternary;
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.52px;
}

@mixin text-homefeed-video-title {
    color: get-color(text, primary);

    font-family: variables.$fontPrimary;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

@mixin text-homefeed-video-show-more {
    color: get-color(text, inverse);

    font-family: variables.$fontPrimary;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

// Video item mixin - used for displaying video items inside a list
// --------------------------------------------------------------------------------------------------------------------
//   .example-usage {
//       @include video-item;
//   }
// --------------------------------------------------------------------------------------------------------------------
@mixin video-item {
    flex-direction: column;

    position: relative;
    margin: 0;
    padding: 0;
    border: none;

    &__campaign {
        position: absolute;
        inset: -8px;

        background-color: #fffeee;
        border-radius: 4px;

        z-index: 0;
    }

    &__img-container {
        width: 100%;
        min-width: unset;
        max-width: unset;
        min-height: unset;
        max-height: unset;
        margin: 0;

        border: 1px solid rgba(102, 109, 110, 0.19);
        background-color: rgba(102, 109, 110, 0.19);

        z-index: 1;

        a {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;

            aspect-ratio: 16 / 9;
            overflow: hidden;
        }
    }

    &__info {
        z-index: 1;
    }

    &__img {
        max-width: fit-content;
        max-height: fit-content;
        width: 100%;
        height: 100%;
    }

    &__video-duration {
        display: inline-block;
        padding: 2px 8px;

        position: absolute;
        bottom: 8px;
        right: 8px;

        font-family: inherit;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.12px;

        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 10px;
    }

    &__progress {
        display: block;
        width: 100%;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        margin: 0;
        padding: 0;

        background-color: rgba(0, 0, 0, 0.6);

        &,
        span {
            height: 6px;
        }

        span {
            display: block;
            background-color: #08aeea;
        }
    }

    &__author {
        position: relative;
        margin: 8px 0 0 0;
        padding-right: 60px;

        color: get-color(text, tertiary);

        /* This font style is not defined as a component in Figma */
        text-overflow: ellipsis;
        font-family: inherit;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.12px;

        span {
            position: absolute;
            top: 0;
            right: 0;

            @include text-caption;

            color: #e5d3bb;
        }
    }

    &__title {
        margin: 8px 0 4px 0;

        @include text-homefeed-video-title;

        a {
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            line-clamp: 3;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &__desc {
        @include text-xs-regular;

        a {
            color: #8b8d92;
            text-transform: none;
        }
    }
}
